import axios from '@/plugins/axios'

const url = 'holidays'
const holiday = {
  namespaced: true,
  actions: {
    async fetchAll({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async doFetch({}) {
      return await axios.get(`/${url}/fetch`)
    },
  },
}

export default holiday
