<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      registration: null,
    }
  },
  mounted() {
    document.addEventListener('swUpdated', this.showNotify)
  },
  beforeDestroy() {
    document.removeEventListener('swUpdated', this.showNotify)
  },
  methods: {
    showNotify() {
      this.$notify.success({
        title: '系統更新通知',
        message: '目前已有新的功能更新，請點擊此通知刷新頁面更新。',
        position: 'bottom-right',
        duration: 10000,
        onClick: () => window.location.reload(true),
        onClose: () => window.location.reload(true),
      })
    },
  },
}
</script>
