import axios from '@/plugins/axios'

const url = 'company'
const company = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_COMPANY: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let data = await axios.get(`/${url}`, { params })
      commit('SET_COMPANY', data)
      return data
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}?t=0`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
    async setDefault({}, id) {
      return await axios.post(`/${url}/default`, { id })
    },
  },
  getters: {
    all: (state) => state.data,
    default: (state) => state.data.find((x) => x.is_default === 1),
  },
}

export default company
