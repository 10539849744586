import axios from '@/plugins/axios'

const url = 'order'
const order = {
  namespaced: true,
  actions: {
    async fetchPage({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async fetchEvent({}, month) {
      return await axios.get(`/${url}/event/${month}`)
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
    async doSyncProduct({}, id) {
      return await axios.put(`/${url}/sync/${id}`)
    },
    async doArrange({}, params) {
      return await axios.put(`/${url}/arrange/${params.id}`, params)
    },
    async doReset({}, id) {
      return await axios.put(`/${url}/reset/${id}`)
    },
    async doFinish({}, id) {
      return await axios.put(`/${url}/finish/${id}`)
    },
    async doDisable({}, id) {
      return await axios.put(`/${url}/disable/${id}`)
    },
  },
}

export default order
