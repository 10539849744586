import axios from 'axios'
import qs from 'qs'
import store from '@/store'

import { Notification, Message } from 'element-ui'

// init
const AxiosInstance = axios.create({
  baseURL: '/api', // api的base_url
  timeout: 15000, // 请求超时时间
  responseType: 'json',
  // withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// request 設定
AxiosInstance.interceptors.request.use(
  (config) => {
    // 序列化
    if (['post', 'put', 'delete'].includes(config.method.toLowerCase)) {
      config.data = qs.stringify(config.data)
    }
    // token
    if (store.getters['auth/token']) {
      config.headers['Authorization'] = store.getters['auth/token']
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response
AxiosInstance.interceptors.response.use(
  (response) => {
    const newToken = response.headers?.authorization
    if (newToken) {
      console.log(newToken)
      store.dispatch('auth/refreshToken', newToken)
    }

    return Promise.resolve(response.data)
  },
  (error) => {
    const { status, data } = error?.response || {}

    if (status === 401) {
      Message.error(data.message)
      // token 過期了
      if (data.message.toLowerCase().includes('token')) {
        store.dispatch('auth/resetAuth', null, { root: true })
        location.assign('/login')
      }
    } else if (status === 422) {
      // validation error
      Notification({
        title: '422 表單驗證錯誤',
        message: data.message,
        type: 'error',
        position: 'bottom-right',
        duration: 5 * 1000,
      })
    } else if (status === 404) {
      // 404 error
      Notification({
        title: '404 查詢對象不存在',
        message: data.message,
        type: 'error',
        position: 'bottom-right',
        duration: 5 * 1000,
      })
    } else {
      console.log(status, data)
      Message.error(data.message)
    }

    return Promise.reject(error.response)
  }
)

export default AxiosInstance
