import axios from '@/plugins/axios'

const url = 'user'
const user = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_USER: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }) {
      let data = await axios.get(`/${url}/all`)
      commit('SET_USER', data)
      return data
    },
    async fetchPage({}, params) {
      params.t = 0
      return await axios.get(`/${url}`, { params })
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}?t=0`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
    async checkUnique({}, account) {
      return await axios.post(`/${url}/unique`, { account })
    },
  },
  getters: {
    all: (state) => state.data,
  },
}

export default user
