import axios from '@/plugins/axios'
import Cookie from 'js-cookie'

const auth = {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem('token') || null,
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data.access_token
      state.user = data.user
      localStorage.setItem('token', data.access_token)
      // 開發時使用 cookie
      Cookie.set('token', data.access_token.split(' ')[1])
    },

    REFRESH_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem('token', token)
      // 開發時使用 cookie
      Cookie.set('token', token.split(' ')[1])
    },

    SET_USER: (state, data) => {
      state.user = data
    },

    RESET_AUTH: (state) => {
      state.user = null
      state.token = null
      localStorage.removeItem('token')
      // 開發時使用 cookie
      Cookie.remove('token')
    },
  },
  actions: {
    // 登入
    async login({ commit }, credential) {
      try {
        let data = await axios.post('/auth/login', credential)
        await commit('SET_TOKEN', data)
        return data
      } catch (e) {
        await commit('RESET_AUTH')
        throw e
      }
    },

    async logout({ commit }) {
      await axios.post('/auth/logout')
      await commit('RESET_AUTH')
      await commit('permission/RESET_ROUTERS', null, { root: true })
      return true
    },

    async refreshToken({ commit }, token) {
      await commit('REFRESH_TOKEN', token)
      return true
    },

    async resetAuth({ commit }) {
      await commit('RESET_AUTH')
      await commit('permission/RESET_ROUTERS', null, { root: true })
      return true
    },

    async fetchUser({ commit }) {
      try {
        let data = await axios.get('/auth/me')
        await commit('SET_USER', data.me)
        return data
      } catch (e) {
        await commit('RESET_AUTH')
        throw e
      }
    },

    async fetchPermission({}) {
      return await axios.get(`/auth/permissions`)
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    check: (state) => state.user !== null,
  },
}

export default auth
