<template>
  <div :class="classObj" class="h-100">
    <div v-if="device === 'mobile' && sidebar" class="drawer-bg" @click.prevent="$store.dispatch('app/closeSidebar')" />
    <sidebar class="sidebar-container" />
    <div class="d-flex flex-column main-container">
      <navbar class="order-first" />
      <tags-view class="order-2 d-none d-md-flex" />
      <section class="app-main order-3">
        <keep-alive :include="$store.getters['app/cachedViews']" :max="10">
          <router-view />
        </keep-alive>
      </section>
      <footer class="order-last footer">
        <strong class="float-right mr-3">&copy; {{ new Date().getFullYear() }} 加鼎工業</strong>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Main',
  computed: {
    ...mapState('app', ['sidebar', 'device']),
    classObj() {
      return {
        'hide-sidebar': !this.sidebar,
        'open-sidebar': this.sidebar,
        mobile: this.device === 'mobile',
      }
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.meta.cache) {
          this.$store.dispatch('app/addCachedView', this.$route)
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    ...mapActions('app', ['updateWindowWidth']),
  },
}
</script>
