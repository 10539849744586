import axios from '@/plugins/axios'

const url = 'monthly-payable'
const monthlyPayable = {
  namespaced: true,
  actions: {
    async fetchPage({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doSplit({}, { id, target_id, payables }) {
      return await axios.post(`/${url}/split/${id}`, { target_id, payables })
    },
    async doMerge({}, id) {
      return await axios.post(`/${url}/merge/${id}`)
    },
  },
}

export default monthlyPayable
