import axios from '@/plugins/axios'

const url = 'customer'
const customer = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_CUSTOMER: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let data = await axios.get(`/${url}/all`, { params })
      commit('SET_CUSTOMER', data)
      return data
    },
    async fetchPage({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
  },
  getters: {
    all: (state) => state.data,
    consumers: (state) => state.data.filter((item) => item.type.includes(1)), // 客戶
    outsources: (state) => state.data.filter((item) => item.type.includes(2)), // 委外廠商
    vendors: (state) => state.data.filter((item) => item.type.includes(4)), // 供料廠商
    molds: (state) => state.data.filter((item) => item.type.includes(8)), // 模具廠商
    others: (state) => state.data.filter((item) => item.type.includes(16)), // 其他
    payable: (state) => state.data.filter((item) => !item.type.includes(1)), //應付款
  },
}

export default customer
