/**
 * color themes
 */
export const colors = [
  'danger',
  'success',
  'orange',
  'primary',
  'info',
  'indigo',
  'pink',
  'teal',
  'warning',
  'secondary',
  'dark',
  'purple',
]

/**
 * 用戶種類
 */
export const userTypes = {
  1: '管理員',
  2: '會計',
  3: '廠長',
  4: '品管',
  5: '生管',
  6: '採購',
  7: '倉儲',
  8: '操作員',
}

/**
 * 客戶種類
 */
export const customerTypes = {
  1: '客戶',
  2: '委外廠商',
  4: '供料廠商',
  8: '模具生產商',
  16: '其他',
}

/**
 * 預設製程種類
 */
export const defaultProcessTypes = {
  0: '無需加工',
  1: '下料',
  2: '一般加工',
  3: '組合',
  4: '委外加工',
  5: '品管檢驗',
  6: '產品包裝',
}

/**
 * 貨幣設定
 */
export const currencies = {
  0: { name: '臺幣', symbol: 'NT$' },
  1: { name: '美金', symbol: 'US$' },
}

/**
 * 計費週期
 */
export const accountingPeriod = {
  0: '前月26日至當月25日',
  1: '前月23日至當月22日',
  2: '前月21日至當月20日',
  3: '前月16日至當月15日',
  4: '前月11日至當月10日',
  5: '前月6日至當月5日',
  6: '前月1日至前月月底',
}

/**
 * 倉儲種類
 */
export const warehouseTypes = {
  0: '成品區',
  1: '半成品區',
  2: '材料區',
  3: '模具區',
  4: '重工區',
  5: '報廢區',
}

/**
 * 重量單位
 */
export const measureTypes = {
  0: '公斤',
  1: '公克',
}

/**
 * 模具狀態
 */
export const moldStatus = {
  0: '刪除',
  1: '已開模',
  2: '尚未開模',
  3: '開模中',
}

/**
 * 模具零件狀態
 */
export const moldUnitStatus = {
  0: '報廢',
  1: '已開模',
  2: '尚未開模',
  3: '開模中',
  4: '維修中',
}

/**
 * 模具訂單狀態
 */
export const moldOrderStatus = {
  0: '取消',
  1: '完成',
  2: '尚未設定',
  3: '開模中',
}

/**
 * 所有權人
 */
export const ownerShip = {
  0: '本廠',
  1: '客戶',
}

/**
 * 報價單狀態
 */
export const quotationStatus = {
  0: '取消',
  1: '完成',
  2: '報價中',
}

/**
 * 訂單狀態
 */
export const orderStatus = {
  0: '取消',
  1: '完成',
  2: '尚未設定完成',
  3: '尚未開模',
  4: '開模中',
  5: '等待排程',
  6: '生產中',
  7: '入庫中',
  8: '出貨中',
  9: '補貨中',
}

/**
 * 送貨方式
 */
export const deliveryMethods = {
  0: '本廠送貨',
  1: '客戶自取',
  2: 'DHL',
  3: 'AZR',
  4: 'SEA',
  5: 'TNT',
  6: 'FedEx',
  7: 'AIR',
  8: 'UPS',
}

/**
 * 製令工單狀態
 */
export const ticketStatus = {
  0: '取消',
  1: '完成',
  2: '進入排程',
  3: '生產加工中',
  4: '採購中',
}

/**
 * 製令工單種類
 */
export const ticketTypes = {
  0: '一般加工',
  1: '重工',
  2: '追加',
}

/**
 * 加工製程狀態
 */
export const processStatus = {
  0: '取消',
  1: '完成',
  2: '等待中',
  3: '準備中',
  4: '生產加工中',
}

export const processIncomingType = {
  0: '點收',
  1: '領料',
  2: '重工（入）',
}

export const processOutgoingType = {
  0: '交接',
  1: '報廢',
  2: '重工（出）',
}

/**
 * 採購單狀態
 */
export const purchaseStatus = {
  0: '已駁回',
  1: '完成',
  2: '尚未審核',
  3: '已下訂單',
  4: '部分到貨',
}

/**
 * 採購項目種類
 */
export const purchaseItemTypes = {
  0: '製令轉出',
  1: '採購申請',
}

/**
 * 採購項目狀態
 */
export const purchaseItemStatus = {
  0: '取消',
  1: '已到貨',
  2: '尚未設定',
  3: '尚未審核',
  4: '等待進貨中',
  5: '部分到貨',
}

export const accountsPayableStatus = {
  0: '取消',
  1: '已付款',
  2: '尚未付款',
}

export const accountsReceivableStatus = {
  0: '取消',
  1: '已銷帳',
  2: '尚未銷帳',
}

export const accountsTypes = {
  0: '委外',
  1: '進貨',
  2: '銷貨',
  3: '進退',
  4: '銷退',
  5: '模具',
  6: '試片',
  7: '貨運',
  8: '訂金',
}

export const returnSubsequent = {
  0: '報廢',
  1: '重工',
}

export const returnDisposal = {
  0: '扣款',
  1: '折讓',
  2: '補送',
}

export const reworkStatus = {
  0: '處理中',
  1: '完成',
}

export const scrapStatus = {
  0: '未處理',
  1: '已報廢',
}

export const monthlyReceivableStatus = {
  0: '取消',
  1: '已沖銷',
  2: '尚未沖銷',
  3: '部分沖銷',
}

export const monthlyPayableStatus = {
  0: '取消',
  1: '已付款',
  2: '尚未付款',
  3: '部分付款',
}

export const shipmentType = {
  1: '出貨',
  2: '補貨',
  3: '補短缺',
}
