import router, { resetRouter, defaultRouters, asyncRouters } from '@/pages/Index/router'

function hasPermission(route, permissions) {
  if (route.meta?.permissions) {
    return permissions.some((permission) => route.meta.permissions.includes(permission))
  } else {
    return true
  }
}

function filterAsyncRouter(routes, permissions) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(tmp, permissions)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })

  return res
}

const permission = {
  namespaced: true,
  state: {
    routers: [],
    addRouters: [],
    permissions: [],
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = defaultRouters.concat(routers)
    },
    RESET_ROUTERS: (state) => {
      state.addRouters = []
      state.routers = defaultRouters
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    RESET_PERMISSIONS: (state) => (state.permissions = []),
  },
  actions: {
    async initRoutes({ commit, state }, permissions) {
      await commit('SET_PERMISSIONS', permissions)
      let accessedRouters = await filterAsyncRouter(asyncRouters, state.permissions)
      await commit('SET_ROUTERS', accessedRouters)
      router.addRoutes(accessedRouters)
    },
    async updatePermissions({ commit, state }, permissions) {
      await commit('SET_PERMISSIONS', permissions)
      await resetRouter()
      let accessedRouters = await filterAsyncRouter(asyncRouters, state.permissions)
      await commit('SET_ROUTERS', accessedRouters)
      router.addRoutes(accessedRouters)
    },
  },
  getters: {
    routers: (state) => state.routers,
    addRouters: (state) => state.addRouters,
    allow: (state) => state.permissions,
    check: (state) => (permissions) => {
      return permissions.some((permission) => state.permissions.includes(permission))
    },
  },
}

export default permission
