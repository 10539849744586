import axios from '@/plugins/axios'
import { groupBy } from 'lodash-es'

const url = 'default-process'
const defaultProcess = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_DEFAULT_PROCESS: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let data = await axios.get(`/${url}`, { params })
      commit('SET_DEFAULT_PROCESS', data)
      return data
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
    async checkUnique({}, name) {
      return await axios.post(`/${url}/unique`, { name })
    },
  },
  getters: {
    all: (state) => state.data, // 所有
    group: (state) => groupBy(state.data, (d) => d.type),
    none: (state) => state.data.filter((item) => item.type === 0), // 無需加工
    feeding: (state) => state.data.filter((item) => item.type === 1), // 下料
    common: (state) => state.data.filter((item) => item.type === 2), // 一般加工
    combine: (state) => state.data.filter((item) => item.type === 3), // 組立
    outsource: (state) => state.data.filter((item) => item.type === 4), // 委外
    qc: (state) => state.data.filter((item) => item.type === 5), // 品管
  },
}

export default defaultProcess
