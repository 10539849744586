import Vue from 'vue'
import { format, parseISO, formatDistanceToNow } from 'date-fns'
import locale from 'date-fns/locale/zh-TW'
import { currencies, measureTypes } from './consts'
import { sumBy } from 'lodash-es'

const filters = {
  Ymd(val) {
    return format(parseISO(val), 'yyyy-MM-dd')
  },
  YmdHis(val) {
    return format(parseISO(val), 'yyyy-MM-dd HH:ii:ss')
  },
  humanDate(val) {
    return formatDistanceToNow(parseISO(val), { addSuffix: true, locale })
  },
  measure(val, type = 0) {
    if (val === undefined) return null
    return (Number(val) || 0).toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + ' ' + measureTypes[type]
  },
  materialName(material) {
    if (!material) return null
    return `[${material.type}]${material.name}`
  },
  currency(val, index = 0, length = 2) {
    if (val === undefined || val === false) return null
    const symbol = currencies[+index]?.symbol || 'NT$'
    const value = Number(val)
    const minus = value < 0 ? '-' : ''
    return `${minus}${symbol}${Math.abs(value)
      .toFixed(length)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`
  },
  currencySymbol(val) {
    return currencies[+val]?.symbol || 'NT$'
  },
  sumBy,
}

Object.keys(filters).forEach((k) => {
  Vue.filter(k, filters[k])
})
