import axios from '@/plugins/axios'

const url = 'part'
const part = {
  namespaced: true,
  actions: {
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
  },
}

export default part
