import axios from '@/plugins/axios'

const url = 'warehouse'
const warehouse = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_WAREHOUSE: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let data = await axios.get(`/${url}/all`, { params })
      commit('SET_WAREHOUSE', data)
      return data
    },
    async fetchPage({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
    async doCreate({}, params) {
      return await axios.post(`/${url}`, params)
    },
    async doUpdate({}, params) {
      return await axios.put(`/${url}/${params.id}`, params)
    },
    async doDelete({}, id) {
      return await axios.delete(`/${url}/${id}`)
    },
    async checkUnique({}, name) {
      return await axios.post(`/${url}/unique`, { name })
    },
  },
  getters: {
    all: (state) => state.data,
    name: (state) => (id) => state.data.find((item) => +item.id === +id)?.name || '未知',
    inventory: (state) => state.data.filter((item) => [0, 4, 5].includes(item.type)) || [], // 盤點：成品 + 重工 + 報廢
    product: (state) => state.data.filter((item) => item.type === 0) || [], //成品
    part: (state) => state.data.filter((item) => item.type === 1) || [], //半成品
    material: (state) => state.data.filter((item) => item.type === 2) || [], // 材料
    mold: (state) => state.data.filter((item) => item.type === 3) || [], // 模具,
    rework: (state) => state.data.filter((item) => item.type === 4) || [], //重工
    scrapped: (state) => state.data.filter((item) => item.type === 5) || [], //報廢
    returns: (state) => state.data.filter((item) => [4, 5].includes(item.type)) || [], //退貨
  },
}

export default warehouse
