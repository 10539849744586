import axios from '@/plugins/axios'

const url = 'tag'
const tag = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_TAG: (state, data) => (state.data = Object.freeze(data)),
  },
  actions: {
    async fetchAll({ commit }, params) {
      let data = await axios.get(`/${url}`, { params })
      commit('SET_TAG', data)
      return data
    },
  },
  getters: {
    all: (state) => state.data,
  },
}

export default tag
