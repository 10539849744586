import axios from '@/plugins/axios'

const url = `stock-part`
const stockPart = {
  namespaced: true,
  actions: {
    async fetchPage({}, params) {
      return await axios.get(`/${url}`, { params })
    },
    async fetchOne({}, id) {
      return await axios.get(`/${url}/${id}`)
    },
  },
}

export default stockPart
