import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-TW' // element-ui lang
import enLocale from './en.json'
import zhLocale from './zh-TW.json'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh_TW: {
    ...zhLocale,
    ...elementZhLocale,
  },
}

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'zh_TW', // set locale
  messages, // set locale messages
})

export default i18n
