// import axios from 'axios'
import { debounce /* , values */ } from 'lodash-es'

const app = {
  namespaced: true,
  state: {
    sidebar: !!+localStorage.getItem('sidebar'),
    device: localStorage.getItem('device') || 'desktop',
    language: localStorage.getItem('language') || 'zh_TW',
    visitedViews: [],
    cachedViews: [],
    usdToTwd: process.env.NODE_ENV === 'production' ? null : 30,
    windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  },
  mutations: {
    TOGGLE_SIDEBAR: (state) => {
      state.sidebar = !state.sidebar
      localStorage.setItem('sidebar', state.sidebar)
    },
    CLOSE_SIDEBAR: (state) => {
      state.sidebar = false
      localStorage.setItem('sidebar', false)
    },
    SET_DEVICE: (state, device) => {
      state.device = device
      localStorage.setItem('device', device)
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      localStorage.setItem('language', language)
    },
    ADD_VISITED_VIEW: (state, view) => {
      let index = state.visitedViews.findIndex((v) => v.path === view.path)
      index < 0 ? state.visitedViews.push(view) : (state.visitedViews[index] = view)
    },
    DEL_VISITED_VIEW: (state, view) => {
      let index = state.visitedViews.findIndex((v) => v.path === view.path)
      if (index >= 0) {
        state.visitedViews.splice(index, 1)
      }

      return state.visitedViews
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (!state.cachedViews.includes(view.name)) {
        state.cachedViews.push(view.name)
      }
    },
    SET_EXCHANGE_RATE: (state, rate) => {
      state.usdToTwd = rate
    },
    SET_WINDOW_WIDTH: (state, width) => {
      state.windowWidth = width * 1
    },
  },
  actions: {
    async toggleSideBar({ commit }) {
      await commit('TOGGLE_SIDEBAR')
    },
    async closeSidebar({ commit }) {
      await commit('CLOSE_SIDEBAR')
    },
    async setDevice({ commit }, device) {
      await commit('SET_DEVICE', device)
      if (device === 'mobile') {
        await commit('CLOSE_SIDEBAR')
      }
    },
    async setLanguage({ commit }, language) {
      await commit('SET_LANGUAGE', language)
    },
    async addVisitedView({ commit }, view) {
      await commit('ADD_VISITED_VIEW', view)
    },
    async delVisitedView({ commit, state }, view) {
      await commit('DEL_VISITED_VIEW', view)
      return state.visitedViews
    },
    async addCachedView({ commit }, view) {
      await commit('ADD_CACHED_VIEW', view)
    },
    async fetchExchangeRate({ commit }) {
      // let result = await axios.get(
      //   `https://free.currencyconverterapi.com/api/v6/convert?q=USD_TWD&compact=ultra&apiKey=070c04a4e44ecc286d79`
      // )

      // let rate = values(result.data)[0]
      await commit('SET_EXCHANGE_RATE', 30)
      return 30
    },
    updateWindowWidth: debounce(async function ({ commit }) {
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      await commit('SET_WINDOW_WIDTH', width)
      await commit('SET_DEVICE', width < 992 ? 'mobile' : 'desktop')
    }, 500),
  },
  getters: {
    sidebar: (state) => state.sidebar,
    device: (state) => state.device,
    language: (state) => state.language,
    cachedViews: (state) => state.cachedViews,
    visitedViews: (state) => state.visitedViews,
    usdToTwd: (state) => state.usdToTwd,
    windowWidth: (state) => state.windowWidth,
  },
}

export default app
