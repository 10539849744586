import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from './Layout'
import qs from 'qs'
import { omitBy } from 'lodash-es'

Vue.use(VueRouter)

export const defaultRouters = [
  {
    // 登入
    name: 'Login',
    path: '/login',
    component: () => import(/* webpackChunkName: "basic" */ '@/views/Auth/Login'),
    meta: { title: '登入', hidden: true },
  },
  {
    // 登出
    name: 'Logout',
    path: '/logout',
    component: () => import(/* webpackChunkName: "basic" */ '@/views/Auth/Logout'),
    meta: { title: '登出', hidden: true },
  },
  {
    // page 404
    name: 'Page404',
    path: '/404',
    component: () => import(/* webpackChunkName: "basic" */ '@/views/Errors/404'),
    meta: { title: '404 找不到', hidden: true },
  },
  {
    // page 401
    name: 'Page401',
    path: '/401',
    component: () => import(/* webpackChunkName: "basic" */ '@/views/Errors/401'),
    meta: { title: '非授權頁面', hidden: true },
  },
  {
    path: '',
    component: Layout,
    redirect: '/dashboard',
    meta: {},
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "basic" */ '@/views/Dashboard/Index'),
        meta: { title: '首頁', icon: 'home', cache: true },
      },
      {
        name: 'Test',
        path: '/test',
        component: () => import(/* webpackChunkName: "basic" */ '@/views/Dashboard/Test'),
        meta: { title: '測試', hidden: true },
      },
    ],
  },
]

export const asyncRouters = [
  {
    name: 'Quotation',
    path: '',
    component: Layout,
    meta: { title: '報價', permissions: [3, 4, 5, 6] },
    children: [
      {
        name: 'QuotationIndex',
        path: '/quotation',
        component: () => import(/* webpackChunkName: "quotation" */ '@/views/Quotation/Index'),
        meta: { title: '報價管理', icon: 'file', cache: true, permissions: [3, 4, 5, 6] },
      },
    ],
  },
  {
    name: 'Order',
    path: '',
    component: Layout,
    meta: { title: '製造訂單', permissions: [7, 8, 9, 10] },
    children: [
      {
        name: 'OrderIndex',
        path: '/order',
        component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Index'),
        meta: { title: '製造訂單管理', icon: 'file-alt', cache: true, permissions: [7, 8, 9, 10] },
      },
      {
        name: 'OrderDetail',
        path: '/order/:id(\\d+)/detail',
        component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Detail'),
        meta: { title: '製造訂單詳細', hidden: true, breadcrumb: false, permissions: [7, 8, 9, 10] },
      },
    ],
  },
  {
    name: 'Product',
    path: '',
    component: Layout,
    meta: { title: '產品', permissions: [15, 16, 17, 18] },
    children: [
      {
        name: 'ProductIndex',
        path: '/product',
        component: () => import(/* webpackChunkName: "product" */ '@/views/Product/Index'),
        meta: { title: '產品管理', icon: 'gift', cache: true, permissions: [15, 16, 17, 18] },
      },
      {
        name: 'PartIndex',
        path: '/product/:id(\\d+)/part',
        component: () => import(/* webpackChunkName: "product" */ '@/views/Part/Index'),
        meta: { title: '配件管理', hidden: true, breadcrumb: false, permissions: [15, 16, 17, 18] },
      },
      {
        name: 'MoldDetail',
        path: '/product/:productId(\\d+)/part/:partId(\\d+)/mold',
        component: () => import(/* webpackChunkName: "product" */ '@/views/Mold/Detail'),
        meta: { title: '模具', hidden: true, breadcrumb: false, permissions: [15, 16, 17, 18] },
      },
    ],
  },
  {
    name: 'Mold',
    path: '',
    component: Layout,
    meta: { title: '模具', icon: 'inbox', permissions: [19, 20, 21, 22, 23, 24, 25, 26] },
    children: [
      {
        name: 'MoldIndex',
        path: '/mold',
        component: () => import(/* webpackChunkName: "mold" */ '@/views/Mold/Index'),
        meta: { title: '模具管理', icon: 'boxes', cache: true, permissions: [19, 20, 21, 22] },
      },
      {
        name: 'MoldOrderIndex',
        path: '/mold/order',
        component: () => import(/* webpackChunkName: "mold" */ '@/views/Mold/OrderIndex'),
        meta: { title: '模具訂單', icon: 'box', cache: true, permissions: [23, 24, 25, 26] },
      },
    ],
  },
  {
    name: 'Ticket',
    path: '',
    component: Layout,
    meta: { title: '生產製程', permissions: [11, 12, 13, 14] },
    children: [
      {
        name: 'TicketIndex',
        path: '/ticket',
        component: () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/Index'),
        meta: { title: '生產製程管理', icon: 'qrcode', breadcrumb: false, cache: true, permissions: [11, 12, 13, 14] },
        redirect: '/ticket/processing',
        children: [
          {
            name: 'TicketProcessing',
            path: '/ticket/processing',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/Processing'),
            meta: { title: '生產加工中', hidden: true, active: 'processing', permissions: [11, 12, 13, 14] },
          },
          {
            name: 'TicketPending',
            path: '/ticket/pending',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/Pending'),
            meta: { title: '進入排程', hidden: true, active: 'pending', permissions: [11, 12, 13, 14] },
          },
          {
            name: 'TicketPurchasing',
            path: '/ticket/purchasing',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/Purchasing'),
            meta: { title: '採購中', hidden: true, active: 'purchasing', permissions: [11, 12, 13, 14] },
          },
          {
            name: 'TicketFinish',
            path: '/ticket/finish',
            component: () => import(/* webpackChunkName: "ticket" */ '@/views/Ticket/Finish'),
            meta: { title: '加工完成', hidden: true, active: 'finish', permissions: [11, 12, 13, 14] },
          },
        ],
      },
    ],
  },
  {
    name: 'Rework',
    path: '',
    component: Layout,
    meta: { title: '重工與報廢', icon: 'tools', permissions: [45, 46, 47, 48, 49, 50, 51, 52] },
    children: [
      {
        name: 'ReworkIndex',
        path: '/rework',
        component: () => import(/* webpackChunkName: "rework" */ '@/views/Rework/Index'),
        meta: { title: '重工管理', icon: 'hammer', cache: true, permissions: [45, 46, 47, 48] },
      },
      {
        name: 'ScrapIndex',
        path: '/scrap',
        component: () => import(/* webpackChunkName: "rework" */ '@/views/Scrap/Index'),
        meta: { title: '報廢管理', icon: 'fill-drip', cache: true, permissions: [49, 50, 51, 52] },
      },
    ],
  },
  {
    name: 'Purchase',
    path: '',
    component: Layout,
    meta: { title: '採購', icon: 'cart-arrow-down', permissions: [27, 28, 29, 30, 31, 32] },
    children: [
      {
        name: 'PurchaseIndex',
        path: '/purchase',
        component: () => import(/* webpackChunkName: "purchase" */ '@/views/Purchase/Index'),
        meta: { title: '採購管理', icon: 'shipping-fast', cache: true, permissions: [27, 28, 29, 30] },
      },
      {
        name: 'PurchasePending',
        path: '/purchase/pending',
        component: () => import(/* webpackChunkName: "purchase" */ '@/views/Purchase/Pending'),
        meta: { title: '採購申請', icon: 'luggage-cart', cache: true, permissions: [31, 32] },
      },
    ],
  },
  {
    name: 'Prepare',
    path: '',
    component: Layout,
    meta: { title: '包裝入庫', breadcrumb: false, permissions: [33, 34, 35, 36] },
    children: [
      {
        name: 'PrepareIndex',
        path: '/prepare',
        component: () => import(/* webpackChunkName: "prepare" */ '@/views/Prepare/Index'),
        meta: { title: '包裝入庫管理', icon: 'coins', cache: true, permissions: [33, 34, 35, 36] },
      },
    ],
  },
  {
    name: 'Receive',
    path: '',
    component: Layout,
    meta: { title: '進貨', icon: 'sign-in-alt', permissions: [37, 38, 39, 40, 41, 42, 43, 44] },
    children: [
      {
        name: 'ReceiveIndex',
        path: '/receive',
        component: () => import(/* webpackChunkName: "receive" */ '@/views/Receive/Index'),
        meta: { title: '進貨管理', icon: 'file-archive', cache: true, permissions: [37, 38, 39, 40] },
      },
      {
        name: 'ReceiveCreate',
        path: '/receive/create',
        component: () => import(/* webpackChunkName: "receive" */ '@/views/Receive/Create'),
        meta: { title: '新增進貨', hidden: true, permissions: [37, 38, 39, 40] },
      },
      {
        name: 'ReceiveReturn',
        path: '/receive/return',
        component: () => import(/* webpackChunkName: "receive" */ '@/views/Receive/Return'),
        meta: { title: '進貨退貨管理', icon: 'file-code', cache: true, permissions: [41, 42, 43, 44] },
      },
    ],
  },
  {
    name: 'Shipment',
    path: '',
    component: Layout,
    meta: { title: '出貨', icon: 'sign-out-alt', permissions: [45, 46, 47, 48, 49, 50, 51, 52] },
    children: [
      {
        name: 'ShipmentIndex',
        path: '/shipment',
        component: () => import(/* webpackChunkName: "shipment" */ '@/views/Shipment/Index'),
        meta: { title: '出貨管理', icon: 'file-image', cache: true, permissions: [45, 46, 47, 48] },
      },
      {
        name: 'ShipmentReturn',
        path: '/shipment/return',
        component: () => import(/* webpackChunkName: "shipment" */ '@/views/Shipment/Return'),
        meta: { title: '出貨退貨管理', icon: 'file-pdf', cache: true, permissions: [49, 50, 51, 52] },
      },
    ],
  },
  {
    name: 'Accounts',
    path: '/accounts',
    component: Layout,
    meta: { title: '會計帳目', icon: 'university', permissions: [53, 54, 55, 56] },
    children: [
      {
        name: 'MonthlyPayableIndex',
        path: '/accounts/monthly-payable',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/MonthlyPayable/Index'),
        meta: { title: '應付賬款管理', icon: 'sign-out-alt', cache: true, permissions: [53, 54] },
      },
      {
        name: 'MonthlyPayableList',
        path: '/accounts/monthly-payable/:id',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/MonthlyPayable/List'),
        meta: { title: '應付賬款管理', hidden: true, permissions: [53, 54] },
      },
      {
        name: 'AccountsPayableIndex',
        path: '/accounts/payable',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/AccountsPayable/Index'),
        meta: { title: '應付帳款管理', hidden: true, cache: true, permissions: [53, 54] },
      },
      {
        name: 'MonthlyReceivableIndex',
        path: '/accounts/monthly-receivable',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/MonthlyReceivable/Index'),
        meta: { title: '應收賬款管理', icon: 'sign-in-alt', cache: true, permissions: [55, 56] },
      },
      {
        name: 'MonthlyReceivableList',
        path: '/accounts/monthly-receivable/:id',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/MonthlyReceivable/List'),
        meta: { title: '應收賬款管理', hidden: true, permissions: [55, 56] },
      },
      {
        name: 'AccountsReceivableIndex',
        path: '/accounts/receivable',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/AccountsReceivable/Index'),
        meta: { title: '應收賬款管理', hidden: true, cache: true, permissions: [55, 56] },
      },
      {
        name: 'AllowanceIndex',
        path: '/accounts/allowance',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/Allowance/Index'),
        meta: { title: '折讓管理', icon: 'ticket-alt', cache: true, permissions: [53, 54, 55, 56] },
      },
    ],
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Layout,
    meta: { title: '一般設定', icon: 'cogs', breadcrumb: false },
    children: [
      // 我的公司
      {
        name: 'CompanyIndex',
        path: '/settings/company',
        component: () => import(/* webpackChunkName: "company" */ '@/views/Company/Index'),
        meta: { title: '我的公司', icon: 'home', cache: true, permissions: [57, 60] },
      },
      {
        name: 'CompanyEdit',
        path: '/settings/company/:id(\\d+)?/:type(create|edit)',
        component: () => import(/* webpackChunkName: "company" */ '@/views/Company/Edit'),
        meta: { title: '我的公司', hidden: true, permissions: [58, 59] },
      },
      // 客戶與廠商
      {
        name: 'CustomerIndex',
        path: '/settings/customer',
        component: () => import(/* webpackChunkName: "customer" */ '@/views/Customer/Index'),
        meta: { title: '客戶與廠商', icon: 'building', cache: true, permissions: [61, 64] },
      },
      {
        name: 'CustomerEdit',
        path: '/settings/customer/:id(\\d+)?/:type(create|edit)',
        component: () => import(/* webpackChunkName: "customer" */ '@/views/Customer/Edit'),
        meta: { title: '客戶與廠商', hidden: true, permissions: [62, 63] },
      },
      // 登入人員
      {
        name: 'UserIndex',
        path: '/settings/user',
        component: () => import(/* webpackChunkName: "user" */ '@/views/User/Index'),
        meta: { title: '帳號管理', icon: 'user', cache: true, permissions: [65, 66, 67, 68] },
      },
      // 角色
      {
        name: 'RoleIndex',
        path: '/settings/role',
        component: () => import(/* webpackChunkName: "user" */ '@/views/Role/Index'),
        meta: { title: '權限角色管理', icon: 'users', permissions: [69, 70, 71, 72] },
      },
      // 預設製程
      {
        name: 'DefaultProcessIndex',
        path: '/settings/default-process',
        component: () => import(/* webpackChunkName: "defaultProcess" */ '@/views/DefaultProcess/Index'),
        meta: { title: '預設製程管理', icon: 'server', cache: true, permissions: [73, 74, 75, 76] },
      },
      // 倉儲管理
      {
        name: 'WarehouseIndex',
        path: '/settings/warehouse',
        component: () => import(/* webpackChunkName: "warehouse" */ '@/views/Warehouse/Index'),
        meta: { title: '倉儲管理', icon: 'cubes', cache: true, permissions: [77, 78, 79, 80] },
      },
      // 材料管理
      {
        name: 'MaterialIndex',
        path: '/settings/material',
        component: () => import(/* webpackChunkName: "material" */ '@/views/Material/Index'),
        meta: { title: '材料管理', icon: 'flask', cache: true, permissions: [81, 82, 83, 84] },
      },
      // 材料種類
      {
        name: 'MaterialType',
        path: '/settings/material-type',
        component: () => import(/* webpackChunkName: "material" */ '@/views/Material/Type'),
        meta: { title: '材料種類管理', icon: 'tint', cache: true, permissions: [85, 86, 87, 88] },
      },
    ],
  },
  { path: '*', redirect: '/404', meta: { hidden: true } },
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: defaultRouters,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    parseQuery(query) {
      return qs.parse(query)
    },
    stringifyQuery(query) {
      return qs.stringify(
        omitBy(query, (p) => p === '' || p === null),
        {
          addQueryPrefix: true,
          encode: false,
          arrayFormat: 'brackets',
        }
      )
    },
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

export default router
